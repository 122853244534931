import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {PopupNotificationService} from './popup-notification.service';
import {environment} from '../../../../../../environments/environment';
import {validateAllFormFields} from '../../../../../shared/components/validator/validator.service';
import {CertificatedService} from '../../../entity/certificated/certificated.service';

@Component({
  selector: 'app-popup-notification',
  templateUrl: './popup-notification.component.html'
})
export class PopupNotificationComponent implements OnInit, OnChanges {

  baseURL = environment.baseURL;

  @Input() display: any;

  notification: any;

  displayDialog: any = {};

  case1Url: string = `${this.baseURL}/iam-sales/agreements/einvoice-customer`;

  ci: any;
  displayCiForm?: boolean;
  ciForm = this.fb.group({
    otherPartyAddr: ['', Validators.required],
    otherRepName: ['', Validators.required],
    otherRepJobTitle: [''],
    otherPhoneNumber: [''],
    otherEmailAddress: [''],
    otherAccountNumber: [''],
    otherBankName: ['']
  });

  constructor(
    private fb: FormBuilder,
    private service: PopupNotificationService,
    private certificatedService: CertificatedService
  ) {
    this.openDialogNotification = this.openDialogNotification.bind(this);
  }

  ngOnInit(): void {
    this.service.get().subscribe(data => {
      this.notification = data;
      if (data?.showAlert === 'Y') {
        setTimeout(() => {
          this.display = Date.now();
        }, 200);
      }
    });
    this.loadCi();
    (window as any)['openDialogNotification'] = this.openDialogNotification;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['display'] && this.display?.detailText) {
      this.notification = this.display;
    }
  }

  loadCi(): void {
    this.service.getCustomerInfo().subscribe(data => {
      this.ciForm.patchValue(data);
      this.ci = data;
    });
  }

  openDialogNotification(cs: string): void {
    this.displayDialog[cs] = true;
  }

  markViewed(): void {
    if (this.notification?.showAlert === 'Y') {
      this.service.markViewed().subscribe(() => {
        this.display = 0;
      });
    } else {
      this.display = 0;
    }
  }

  showCiForm(): void {
    this.displayCiForm = true;
  }

  submitCiForm(): void {
    if (validateAllFormFields(this.ciForm)) {
      this.case1Url = '';
      this.service.setCustomerInfo(this.ciForm.value).subscribe(() => {
        this.displayCiForm = false;
        this.case1Url = `${this.baseURL}/iam-sales/agreements/einvoice-customer`;
      });
    }
  }

  signCase1(): void {
    this.case1Url = '';
    this.certificatedService.run({
      idValue: this.ci.agreementId,
      actionType: 'agreementSeller'
    }).then(() => {
      this.loadCi();
    }).catch(() => {
    }).finally(() => {
      this.case1Url = `${this.baseURL}/iam-sales/agreements/einvoice-customer`;
    });
  }

}
