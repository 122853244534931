import {Component, Input, OnInit} from '@angular/core';

import {OrdersService} from './orders.service';

@Component({
  selector: 'app-order-products',
  templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit {

  @Input() order: any;

  items: Array<any> = new Array<any>();

  constructor(
    private ordersService: OrdersService
  ) {
  }

  ngOnInit(): void {
    if (this.order && this.order.orderId) {
      this.ordersService.getItems(this.order.orderId).subscribe(({items}) => {
        this.items = items;
      });
    }
  }

}
