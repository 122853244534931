<div class="iam-topbar">
  <div class="iam-topbar-left">
    <div class="p-pt-1 p-pl-3">
      <a routerLink="/" title="VININVOICE">
        <img src="assets/vin-invoice/logo-VININVOICE-PNG.png" alt="VININVOICE" width="180px">
      </a>
    </div>
  </div>
  <div class="iam-topbar-right">
    <div class="iam-topbar-menu">
      <div class="p-grid p-ml-5 p-pt-2" *ngIf="AuthService.hasRoles(['Customer', 'CusManager'])">
        <div routerLink="/sold" routerLinkActive="active-module" class="main-menu pointer" style="width: 14rem;padding: 5px;">
          <img src="assets/vin-invoice/invoice.png" class="p-mt-2" alt="VININVOICE" style="width: 40px;float: left">
          <div style="font-size: 15px; font-weight: bold; padding: 8px 0 0 45px;">Hóa đơn <br/> điện tử</div>
        </div>
        <div routerLink="/purchase" routerLinkActive="active-module" class="main-menu pointer" style="width: 14rem;padding: 5px;">
          <img src="assets/vin-invoice/manage.png" class="p-mt-2" alt="VININVOICE" style="width: 40px;float: left">
          <div style="font-size: 15px; font-weight: bold; padding: 8px 0 0 45px;">Quản lý <br/> Hóa đơn</div>
        </div>
        <div routerLink="/personal-income-tax" routerLinkActive="active-module" class="main-menu pointer" style="width: 14rem;padding: 5px;">
          <img src="assets/vin-invoice/personal.png" class="p-mt-2" alt="VININVOICE" style="width: 40px;float: left">
          <div style="font-size: 15px; font-weight: bold; padding: 8px 0 0 45px;">Quản lý <br/> Chứng từ TNCN</div>
        </div>
      </div>
    </div>
    <ul class="iam-topbar-actions">
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'Hotline')" title="Hỗ trợ khách hàng">
          <img src="assets/vin-invoice/icon-cskh.png" alt="" width="50">
        </a>
        <ul *ngIf="entities.activeTopbarAction === 'Hotline'" class="iam-topbar-action-panel p-shadow-6" style="min-width: 16rem;">
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" href="https://zalo.me/83603229501305249" target="_blank" title="Hỗ trợ trực tiếp">
              <img src="assets/vin-invoice/zalo-60x60.png" alt="" class="p-mr-3" width="30">
              <span>Hỗ trợ trực tiếp</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" href="tel:0985172117" title="Hotline">
              <img src="assets/vin-invoice/icon-Phone.png" alt="" class="p-mr-3" width="30">
              <span>Hotline: 0985172117</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'QuickCreate')" title="{{ 'QuickCreate'|translate }}"
           *ngIf="AuthService.hasRoles(['Customer'])">
          <i class="pi pi-plus-circle pi-large"></i>
        </a>
        <div *ngIf="entities.activeTopbarAction === 'QuickCreate'" class="iam-topbar-action-panel p-shadow-6 p-p-3">
          <div class="p-mb-3">
            <span style="font-size: 20px;">{{ 'QuickCreate'|translate }}</span>
          </div>
          <div class="p-d-flex">
            <div class="p-mr-5" style="width: 150px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Hóa đơn điện tử</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/invoice/hdbh/create">Hóa đơn bán hàng</div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/invoice/pxk/create">Phiếu xuất kho</div>
              </div>
              <div class="p-grid" style="margin-top: 8px;">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Xử lý hóa đơn điện tử</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/invoice/cancelled">Hủy hóa đơn</div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/invoice/replaced">Thay thế hóa đơn</div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/invoice/adjusted">Điều chỉnh hóa đơn</div>
              </div>
            </div>
            <div style="width: 175px;margin-right: 3.3rem;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Cấu hình hóa đơn điện tử</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/register/invoice-templates/create">Mẫu hóa đơn</div>
              </div>
              <div class="p-grid" style="margin-top: 39px;">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Danh mục HĐ Điện tử</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/category/buyers/create">Khách hàng</div>
                <div class="p-col-12 pointer" routerLink="/sold/customer/category/products/create">Hàng hóa, dịch vụ</div>
              </div>
            </div>
            <div style="width: 150px;margin-right: 3.3rem;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Thu nhập cá nhân</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/personal-income-tax/tax-deduction/create">Chứng từ TNCN</div>
              </div>
              <div class="p-grid" style="margin-top: 39px;">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Danh mục TNCN</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/personal-income-tax/employees">Người lao động</div>
                <div class="p-col-12 pointer" routerLink="/personal-income-tax/positions">Vị trí công việc</div>
              </div>
            </div>
            <div style="width: 140px;">
              <div class="p-grid">
                <div class="p-col-12">
                  <b style="border-bottom: 1px solid #1492e6;line-height: 20px;display: inline-block;">Quản lý hóa đơn</b>
                </div>
                <div class="p-col-12 pointer" routerLink="/purchase/authenticate">Kết nối cơ quan thuế</div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="viewNotification($event)" title="Thông báo"
           *ngIf="AuthService.hasRoles(['Customer'])">
          <i class="pi pi-bell pi-large"></i>
          <span class="p-badge p-badge-no-gutter p-badge-success" style=" position: absolute; right: 0; top: 0; padding: 0 2px 0 2px;">{{ noticeCount|number }}</span>
        </a>
        <div *ngIf="entities.activeTopbarAction === 'Notification'" class="iam-topbar-action-panel p-shadow-6 p-p-3">
          <div class="p-mb-3">
            <span style="font-size: 20px;">{{ 'Thông báo'|translate }}</span>
          </div>
          <div class="p-grid iam-scroll" style="width: 30rem; max-height: 400px; overflow: hidden auto;">
            <div class="p-col-12" *ngFor="let notification of notifications">
              <div class="p-card">
                <div class="p-grid p-px-3">
                  <div class="p-col-1 p-pb-0">
                    <i class="pi pi-volume-up" style="margin: 3px; font-size: 19px; color: #0c5460;" *ngIf="notification['typeString'] === 'info'"></i>
                    <i class="pi pi-exclamation-circle" style="margin: 3px; font-size: 19px; color: #FF9800;" *ngIf="notification['typeString'] === 'warning'"></i>
                  </div>
                  <div class="p-col-11 p-pb-0">
                    <div class="p-grid">
                      <div class="p-col-12" [innerHtml]="notification['titleText']|safeHtml"></div>
                      <div class="p-col-12">
                        <div class="p-grid">
                          <div class="p-col-6">
                            <span class="alert-success pointer" (click)="viewNotificationDetail(notification)" *ngIf="notification['detailText']">Xem chi tiết</span>
                          </div>
                          <div class="p-col-6">
                            <p class="p-mb-0 p-text-right"><i>{{ notification['sentDate']|date:'dd/MM/yyyy HH:mm:ss' }}</i></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'Download')" title="{{ 'Download'|translate }}">
          <i class="pi pi-download pi-large"></i>
        </a>
        <ul *ngIf="entities.activeTopbarAction === 'Download'" class="iam-topbar-action-panel p-shadow-6" style="min-width: 16rem;">
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" href="/assets/tools/vininvoice.zip">
              <i class="pi pi-download p-mr-2"></i>
              <span>Tải phần mềm ký</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" href="/assets/tools/NET-Framework-4.5.2.exe">
              <i class="pi pi-download p-mr-2"></i>
              <span>Tải .NET Framework 4.5.2</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" href="/assets/tools/huong-dan-su-dung-vin-chung-tu.pdf" target="_blank">
              <i class="pi pi-download p-mr-2"></i>
              <span>Tài liệu HDSD</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="iam-topbar-action">
        <a pRipple class="rounded-circle" (click)="entities.onTopbarActionClick($event, 'Profile')" title="{{ 'Profile'|translate }}">
          <ng-container *ngIf="!logoData">
            <i class="pi pi-user pi-large"></i>
          </ng-container>
          <ng-container *ngIf="logoData">
            <img [src]="logoData" alt="" style="border-radius: 50%; width: 40px; height: 40px;">
          </ng-container>
          <span class="p-ml-2" *ngIf="user">{{ displayName }}</span>
        </a>
        <ul *ngIf="entities.activeTopbarAction === 'Profile'" class="iam-topbar-action-panel p-shadow-6">
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" routerLink="/home/organization-info">
              <i class="pi pi-user p-mr-2"></i>
              <span>Thông tin tài khoản</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" routerLink="/home/user-accounts">
              <i class="pi pi-users p-mr-2"></i>
              <span>Quản lý người dùng</span>
            </a>
          </li>

          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" (click)="changePassword()">
              <i class="pi pi-unlock p-mr-2"></i>
              <span>{{ 'ChangePassword'|translate }}</span>
            </a>
          </li>
          <li class="layout-topbar-action-item">
            <a pRipple class="p-d-flex p-flex-row p-ai-center" (click)="logout()">
              <i class="pi pi-power-off p-mr-2"></i>
              <span>{{ 'Logout'|translate }}</span>
            </a>
          </li>
          <li class="p-pt-2 p-pr-3 p-pl-3 p-text-center">
            <span style="font-size: 12px;color: #616161;">{{ user['partyName'] }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
  <p-dialog header="{{ 'ChangePassword'|translate }}" [(visible)]="changingPassword" [style]="{width: '500px'}" [resizable]="false" position="top">
    <p-message severity="info" text="Xin quý khách lưu ý, để đảm bảo an ninh tài khoản nên dùng một mật khẩu đầy đủ yếu tố sau: Sử dụng ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và số."></p-message>
    <div class="p-fluid p-formgrid p-grid p-mt-2">
      <div class="p-field p-col-12">
        <label for="oldPassword">{{ 'Password'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="oldPassword" formControlName="oldPassword">
        <app-validator [control]="changePasswordForm.get('oldPassword')"></app-validator>
      </div>
      <div class="p-field p-col-12">
        <label for="newPassword">{{ 'NewPassword'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="newPassword" formControlName="newPassword">
        <app-validator [control]="changePasswordForm.get('newPassword')"></app-validator>
      </div>
      <div class="p-field p-col-12">
        <label for="newPasswordVerify">{{ 'NewPasswordVerify'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="newPasswordVerify" formControlName="newPasswordVerify">
        <app-validator [control]="changePasswordForm.get('newPasswordVerify')"></app-validator>
      </div>
    </div>
    <p-message severity="error" [text]="changePasswordError" *ngIf="changePasswordError"></p-message>
    <p-footer>
      <p-button label="{{ 'ChangePassword'|translate }}" icon="pi pi-check" styleClass="p-button-raised" type="submit" [disabled]="changePasswordForm.invalid"></p-button>
      <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="changingPassword=false"></p-button>
    </p-footer>
  </p-dialog>
</form>

<app-popup-notification [display]="notification"></app-popup-notification>
