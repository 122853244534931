<p-dialog showHeader="false" visible="true" [style]="{width: '60rem'}" resizable="false" draggable="false">
  <div>
    <h5>THÔNG BÁO VỀ VIỆC BẢO TRÌ HỆ THỐNG HOÁ ĐƠN, CHỨNG TỪ ĐIỆN TỬ VININVOICE</h5>
    <p>Kính gửi quý khách hàng,</p>
    <p>VININVOICE sẽ tiến hành nâng cấp bảo trì cho hệ thống hóa đơn điện tử, cụ thể như sau:</p>
    <p style="color: red; font-weight: bold;">Từ 8h00 đến 10h00 ngày hôm nay (29/11/2023)</p>
    <p>Quý khách hàng lưu ý trong thời gian trên, VININVOICE sẽ tạm dừng hệ thống hóa đơn điện tử, sau khi đó hệ thống sẽ tiếp tục hoạt động trở lại bình thường.</p>
    <p>Xin cảm ơn sự phối hợp của quý khách hàng.</p>
  </div>
</p-dialog>
