import {Component, OnInit} from '@angular/core';

import {LazyLoadEvent} from 'primeng/api';

import {Enums} from '../../../../shared/enums';
import {OrdersService} from './orders.service';

import {environment} from '../../../../../environments/environment';
import {CertificatedService} from '../../../components/entity/certificated/certificated.service';
import {ToastService} from '../../../../shared/components/toast/toast.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit {

  baseURL: string = environment.baseURL;

  booleans: Array<any> = Enums.Booleans;

  selectedItem: any;
  items: Array<any> = new Array<any>();
  totalRecords: number = 0;

  constructor(
    private itemsService: OrdersService,
    private certificatedService: CertificatedService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
  }

  reLoadLazy(): void {
    this.loadLazy({first: 0, rows: 10, filters: {}});
  }

  loadLazy(event: LazyLoadEvent): void {
    this.itemsService.getList(JSON.stringify(event)).subscribe((response) => {
      this.totalRecords = response?.totalRecords;
      this.items = response?.listData;
    });
  }

  viewPdf(rowData: any): void {
    if (rowData && rowData.agreementId) {
      window.open(`${this.baseURL}/iam-sales/agreements/${rowData.agreementId}/pdf`);
    }
  }

  sign(rowData: any): void {
    this.certificatedService.run({
      idValue: rowData.agreementId,
      actionType: 'agreementSeller'
    }).then(() => {
      this.toastService.success();
      this.reLoadLazy();
    }).catch(() => {
    });
  }

}
