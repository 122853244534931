<p-tabView>
  <p-tabPanel header="Hồ sơ doanh nghiệp">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="p-fluid p-formgrid p-grid detail">

        <div class="p-field p-col-12">
          <p-fieldset legend="{{ 'Thông tin đơn vị'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="partyTaxId">{{ 'TaxCode'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="partyTaxId" formControlName="partyTaxId">
                <app-validator [control]="form.get('partyTaxId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="partyName">{{ 'OrganizationName'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="partyName" formControlName="partyName">
                <app-validator [control]="form.get('partyName')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="emailAddress">{{ 'Email'|translate }}</label>
                <input pInputText type="email" id="emailAddress" formControlName="emailAddress" [email]="true">
                <app-validator [control]="form.get('emailAddress')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="phoneNumber">{{ 'Phone'|translate }}</label>
                <input id="phoneNumber" pInputText type="text" formControlName="phoneNumber" [maxlength]="20">
                <app-validator [control]="form.get('phoneNumber')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="faxNumber">{{ 'FaxNumber'|translate }}</label>
                <input pInputText type="text" id="faxNumber" formControlName="faxNumber">
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="webAddress">{{ 'WebAddress'|translate }}</label>
                <input pInputText type="text" id="webAddress" formControlName="webAddress">
                <app-validator [control]="form.get('webAddress')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="stateProvinceGeoId">{{ 'Province'|translate }}<span class="required">*</span></label>
                <p-dropdown id="stateProvinceGeoId" [options]="provinces" [filter]="true" formControlName="stateProvinceGeoId"></p-dropdown>
                <app-validator [control]="form.get('stateProvinceGeoId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <app-county-geo [abstractControl]="form.get('countyGeoId')" [required]="true" [stateProvinceGeoId]="form.get('stateProvinceGeoId')?.value"></app-county-geo>
                <app-validator [control]="form.get('countyGeoId')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="address1">{{ 'Địa chỉ đăng ký kinh doanh'|translate }}<span class="required">*</span></label>
                <input pInputText type="text" id="address1" formControlName="address1">
                <app-validator [control]="form.get('address1')"></app-validator>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="p-field p-col-12">
          <p-fieldset legend="{{ 'Representative'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-6">
                <label for="representativeName">{{ 'RepresentativeName'|translate }}</label>
                <input pInputText type="text" id="representativeName" formControlName="representativeName">
                <app-validator [control]="form.get('representativeName')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="representativeJobTitle">{{ 'RepresentativeJobTitle'|translate }}</label>
                <input pInputText type="text" id="representativeJobTitle" formControlName="representativeJobTitle">
                <app-validator [control]="form.get('representativeJobTitle')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="representativeEmail">{{ 'Email'|translate }}</label>
                <input pInputText type="email" id="representativeEmail" formControlName="representativeEmail" [email]="true">
                <app-validator [control]="form.get('representativeEmail')"></app-validator>
              </div>
              <div class="p-field p-col-12 p-md-3">
                <label for="representativePhoneNumber">{{ 'Phone'|translate }}</label>
                <input id="representativePhoneNumber" pInputText type="text" formControlName="representativePhoneNumber" [maxlength]="20">
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="p-field p-col-6">
          <p-fieldset legend="{{ 'Thông tin cơ quan thuế chủ quản'|translate }}">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-md-6">
                <app-tax-authority [abstractControl]="form.get('taxAuthorityId')" [stateProvinceGeoId]="form.get('stateProvinceGeoId')?.value"></app-tax-authority>
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="p-field p-col-6">
          <p-fieldset legend="{{ 'Thông tin thanh toán'|translate }}">
            <div class="p-un-fluid p-grid p-mb-2" *ngIf="updating">
              <div class="p-col-12 p-text-right">
                <p-button label="{{ 'Add'|translate }}" icon="pi pi-plus" styleClass="p-button-outlined p-button-sm" (onClick)="createBankAccount()"></p-button>
              </div>
            </div>

            <p-table [value]="form.get('bankAccounts')?.value" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
              <ng-template pTemplate="header">
                <tr>
                  <th [width]="40" class="p-text-center">#</th>
                  <th>{{ 'BankAccount'|translate }}</th>
                  <th>{{ 'BankName'|translate }}</th>
                  <th [width]="80" *ngIf="updating"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                <tr>
                  <td class="p-text-center">{{ (index + 1)|number }}</td>
                  <td>{{ rowData['bankAccount'] }}</td>
                  <td>{{ rowData['bankName'] }}</td>
                  <td class="p-text-center action-column" *ngIf="updating">
                    <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-warning" (onClick)="updateBankAccount(rowData)" title="{{ 'Update'|translate }}"></p-button>
                    <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger" (onClick)="deleteBankAccount($event, rowData)" title="{{ 'Delete'|translate }}"></p-button>
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </p-fieldset>
        </div>

        <div class="p-field p-col-6">
          <p-fieldset legend="{{ 'Logo'|translate }}">

            <div>
              <ng-container *ngIf="logoData">
                <img [src]="logoData" alt="" width="200" (click)="logo.click()">

              </ng-container>
              <ng-container *ngIf="!logoData">
                <img src="/assets/vin-invoice/Blank-Avatar.png" alt="" width="200" (click)="logo.click()">
              </ng-container>

            </div>

            <input type="file" #logo accept="image/*" style="display: none;" (change)="onUploadLogo($event)">

            <div class="p-un-fluid">
              <ng-container *ngIf="!logoData">
                <p-button label="Upload ảnh" icon="pi pi-pencil" styleClass="p-button-raised p-button-sm p-mr-2" (onClick)="logo.click()"></p-button>
              </ng-container>
              <ng-container *ngIf="logoData">
                <p-button label="Đổi ảnh" icon="pi pi-pencil" styleClass="p-button-raised p-button-sm p-mr-2" (onClick)="logo.click()"></p-button>
                <p-button label="Xoá ảnh" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-sm" (onClick)="onDeleteLogo()"></p-button>
              </ng-container>
            </div>

          </p-fieldset>
        </div>
        <div class="p-field p-col-6">
          <p-fieldset legend="Hồ sơ kê khai thuế">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-12">
                <label for="tenNganhNgheKD">Ngành nghề kinh doanh chính</label>
                <input pInputText type="text" id="tenNganhNgheKD" formControlName="tenNganhNgheKD">
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="tenDviPthuoc">Tên đơn vị phụ thuộc/địa điểm kinh doanh của hoạt động sản xuất kinh doanh khác tỉnh nơi đóng trụ sở chính</label>
                <input pInputText type="text" id="tenDviPthuoc" formControlName="tenDviPthuoc">
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="mstDviPthuoc">Mã số thuế đơn vị phụ thuộc/Mã số địa điểm kinh doanh</label>
                <input pInputText type="text" id="mstDviPthuoc" formControlName="mstDviPthuoc">
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="ct11ProvinceGeoId">Tỉnh/Thành phố</label>
                <p-dropdown id="ct11ProvinceGeoId" [options]="provinces" [filter]="true" formControlName="ct11ProvinceGeoId"></p-dropdown>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <app-county-geo [abstractControl]="form.get('ct11CountyGeoId')" [required]="true" [stateProvinceGeoId]="form.get('ct11ProvinceGeoId')?.value"></app-county-geo>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="ct11WardName">Phường/xã</label>
                <input pInputText type="text" id="ct11WardName" formControlName="ct11WardName">
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="soHdongDlyThue">Số hợp đồng đại lý thuế</label>
                <input pInputText type="text" id="soHdongDlyThue" formControlName="soHdongDlyThue">
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="ngayHdongDlyThue">Ngày hợp đồng đại lý thuế</label>
                <p-calendar id="ngayHdongDlyThue" formControlName="ngayHdongDlyThue" dateFormat="dd/mm/yy"></p-calendar>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="nvienDlyThue">Nhân viên đại lý thuế</label>
                <input pInputText type="text" id="nvienDlyThue" formControlName="nvienDlyThue">
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="cchiDlyThue">Chứng chỉ hành nghề số</label>
                <input pInputText type="text" id="cchiDlyThue" formControlName="cchiDlyThue">
              </div>
            </div>
          </p-fieldset>
        </div>

      </div>
      <div class="p-field p-col-12 p-text-right">
        <div *ngIf="!updating">
          <p-button label="{{ 'Edit'|translate }}" icon="pi pi-pencil" styleClass="p-button-raised p-button-warning" (onClick)="update()"
                    *ngIf="AuthService.hasPrivilege('OrganizationInfo', 'OrganizationInfo1', 'UPDATE')"></p-button>
        </div>
        <div *ngIf="updating">
          <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-outlined p-button-secondary" (onClick)="cancelUpdate()"></p-button>
          <p-button label="{{ 'Update'|translate }}" icon="pi pi-check" styleClass="p-button-raised p-ml-2" type="submit" [disabled]="submitted"></p-button>
        </div>
      </div>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Thông tin gói dịch vụ" [selected]="tab === 'consume'">
    <ng-template pTemplate="content">
      <app-consume-review></app-consume-review>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Tờ khai TT78" *ngIf="decreeCircular === 'DecCir78'">
    <ng-template pTemplate="content">
      <app-message-100-accepted></app-message-100-accepted>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Lịch sử thay đổi">
    <ng-template pTemplate="content">
      <app-changelogs></app-changelogs>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Hợp đồng đã mua">
    <ng-template pTemplate="content">
      <app-orders></app-orders>
    </ng-template>
  </p-tabPanel>
</p-tabView>

<form [formGroup]="bankAccountForm" (ngSubmit)="onBankAccountSubmit()" autocomplete="off">
  <p-dialog header="{{ 'ContactPerson'|translate }}" [(visible)]="creatingBankAccount" [style]="{width: '450px'}" [resizable]="false" styleClass="no-scroll">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="CP-bankAccount">{{ 'BankAccount'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="CP-bankAccount" formControlName="bankAccount">
        <app-validator [control]="bankAccountForm.get('bankAccount')"></app-validator>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="CP-bankName">{{ 'BankName'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="CP-bankName" formControlName="bankName">
        <app-validator [control]="bankAccountForm.get('bankName')"></app-validator>
      </div>
    </div>
    <p-footer>
      <p-button label="{{ 'Save'|translate }}" styleClass="p-button-raised" type="submit" [disabled]="bankAccountForm.invalid"></p-button>
      <p-button label="{{ 'Close'|translate }}" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="creatingBankAccount=false"></p-button>
    </p-footer>
  </p-dialog>
</form>
