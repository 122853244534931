import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupNotificationService {

  private readonly url = 'iam-common/popup-notification';

  constructor(private http: HttpClient) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.url}`);
  }

  markViewed(): Observable<any> {
    return this.http.put(`${this.url}/mark-viewed`, {});
  }

  getCustomerInfo(): Observable<any> {
    return this.http.get(`/iam-sales/agreements/customer-info`);
  }

  setCustomerInfo(data: any): Observable<any> {
    return this.http.put(`/iam-sales/agreements/customer-info`, data);
  }

}
