<p-table [value]="items" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines iam-datatable-scrollable-x">
  <ng-template pTemplate="header">
    <tr>
      <th [width]="200" pSortableColumn="pseudoId">{{ 'PseudoId'|translate }}
        <p-sortIcon field="pseudoId"></p-sortIcon>
      </th>
      <th pSortableColumn="productName">{{ 'ProductName'|translate }}
        <p-sortIcon field="productName"></p-sortIcon>
      </th>
      <th [width]="150" pSortableColumn="price">{{ 'Price'|translate }}
        <p-sortIcon field="price"></p-sortIcon>
      </th>
      <th [width]="150" pSortableColumn="amountValue">{{ 'Quantity'|translate }}
        <p-sortIcon field="amountValue"></p-sortIcon>
      </th>
      <th [width]="150" pSortableColumn="quantityPromoDesc">SL khuyến mại
        <p-sortIcon field="quantityPromoDesc"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData['pseudoId'] }}</td>
      <td>{{ rowData['productName'] }}</td>
      <td class="p-text-right">{{ rowData['price']|number }}</td>
      <td>{{ rowData['amountValue'] }}</td>
      <td>{{ rowData['quantityPromoDesc'] }}</td>
    </tr>
  </ng-template>
</p-table>
