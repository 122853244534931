import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {MenuItem} from 'primeng/api';

import {ItemsService as OrganizationService} from '../../../home/organization-info';

import {BreadcrumbService} from '../breadcrumb/breadcrumb.service';
import {AuthService} from '../../../../shared/service/auth.service';
import {TranslateService} from '../../../../shared/translate/translate.service';
import {AppSubject} from '../../../../shared/app-subject';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {

  @Input() activeModule?: string;

  menus: Array<MenuItem> = new Array<MenuItem>();

  decreeCircularSubscription?: Subscription;
  decreeCircular?: string;

  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private translate: TranslateService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.setBreadcrumb(event.urlAfterRedirects);
    });
  }

  ngOnInit(): void {
    if (AuthService.hasRoles(['Customer', 'CusManager'])) {
      this.decreeCircularSubscription = AppSubject.getDecreeCircular().subscribe((decreeCircular) => {
        this.decreeCircular = decreeCircular;
        this.initMenu();
      });
      this.loadDecreeCircular();
    } else {
      this.initMenu();
    }
  }

  loadDecreeCircular(): void {
    this.organizationService.get().subscribe((response) => {
      if (response && response.decreeAndCircularUsing) {
        AppSubject.setDecreeCircular(response.decreeAndCircularUsing);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeModule']) {
      this.initMenu();
    }
  }

  initMenu(): void {
    if (AuthService.hasRoles(['Customer', 'CusManager'])) {
      switch (this.activeModule) {
        case 'sold':
          this.menus = this.getCustomerSoldMenus();
          break;
        case 'purchase':
          this.menus = this.getCustomerPurchaseMenus();
          break;
        case 'personal-income-tax':
          this.menus = this.getCustomerTaxMenus();
          break;
        default:
          this.menus = [
            {
              label: 'Thêm mới hoá đơn',
              icon: 'pi pi-fw pi-book',
              routerLink: '/sold/customer/invoice/hdbh/create'
            },
            {
              label: 'Thêm mới PXK',
              icon: 'pi pi-fw pi-file',
              routerLink: '/sold/customer/invoice/pxk/create'
            },
            {
              label: 'Thêm chứng từ TNCN',
              icon: 'pi pi-fw pi-calendar',
              routerLink: '/personal-income-tax/tax-deduction/create'
            },
            {
              label: 'Đồng bộ hóa đơn',
              icon: 'pi pi-fw pi-history',
              routerLink: '/purchase/authenticate'
            }
          ];
      }
    } else {
      this.menus = this.getManagerSoldMenus();
    }
    this.menus.forEach((menu) => {
      if (menu.items) {
        let visible = false;
        menu.items.forEach(item => {
          if (item.visible !== false) {
            visible = true;
          }
        });
        if (menu.visible === undefined) {
          menu.visible = visible;
        }
      }
    });
    this.setBreadcrumb();
  }

  ngOnDestroy(): void {
    if (this.decreeCircularSubscription) {
      this.decreeCircularSubscription.unsubscribe();
    }
  }

  getCustomerTaxMenus(): Array<MenuItem> {
    return [
      // {
      //   label: this.translate.get('Dashboard'),
      //   icon: 'pi pi-fw pi-desktop',
      //   routerLink: '/personal-income-tax/dashboard'
      // },
      {
        label: 'Chứng từ khấu trừ thuế',
        icon: 'pi pi-fw pi-calendar',
        routerLink: '/personal-income-tax/tax-deduction'
      },
      {
        label: 'Người lao động',
        icon: 'pi pi-fw pi-id-card',
        routerLink: '/personal-income-tax/employees'
      },
      {
        label: 'Báo cáo',
        icon: 'pi pi-fw pi-chart-pie',
        styleClass: 'iam-menu-22',
        items: [
          {label: 'Bảng kê sử dụng chứng từ', routerLink: '/personal-income-tax/report/using-report', icon: 'pi pi-fw pi-circle-off'}
        ]
      },
      {
        label: this.translate.get('Thiết lập'),
        icon: 'pi pi-fw pi-cog',
        styleClass: 'iam-menu-16',
        items: [
          {label: 'Mẫu số, ký hiệu chứng từ', routerLink: '/personal-income-tax/templates', icon: 'pi pi-fw pi-circle-off'},
          // {label: 'Thông tin đơn vị', routerLink: '/personal-income-tax/root-info', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Cơ cấu tổ chức', routerLink: '/personal-income-tax/organization-unit', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Vị trí công việc', routerLink: '/personal-income-tax/positions', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Tải mẫu tờ khai đăng ký', url: '/assets/tools/to-khai-dang-ky-chung-thu.rar', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Điều khoản sử dụng', routerLink: '/personal-income-tax/system/terms', icon: 'pi pi-fw pi-circle-off'}
        ]
      }
    ];
  }

  getCustomerPurchaseMenus(): Array<MenuItem> {
    return [
      // {
      //   label: this.translate.get('Dashboard'),
      //   icon: 'pi pi-fw pi-desktop',
      //   routerLink: '/purchase/dashboard'
      // },
      {
        label: 'Hóa đơn bán ra',
        icon: 'pi pi-fw pi-calendar',
        routerLink: '/purchase/invoices/sales'
      },
      {
        label: 'Hóa đơn mua vào',
        icon: 'pi pi-fw pi-calendar',
        routerLink: '/purchase/invoices/purchase'
      },
      {
        label: 'Báo cáo',
        icon: 'pi pi-fw pi-chart-pie',
        styleClass: 'iam-menu-22',
        items: [
          // {label: 'Hóa đơn không hợp lệ', routerLink: '/purchase/report/using-report', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Tờ khai thuế 01GTGT', routerLink: '/purchase/report/to-khai-thue-01', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Xuất file Excel PM kế toán', routerLink: '/purchase/report/xuat-file-excel', icon: 'pi pi-fw pi-circle-off'}
        ]
      },
      {
        label: 'Kết nối cơ quan thuế',
        icon: 'pi pi-fw pi-cog',
        routerLink: '/purchase/authenticate'
      },
      {
        label: this.translate.get('Thiết lập'),
        icon: 'pi pi-fw pi-cog',
        styleClass: 'iam-menu-16',
        items: [
          {label: 'Điều khoản sử dụng', routerLink: '/purchase/system/terms', icon: 'pi pi-fw pi-circle-off'}
        ]
      }
    ];
  }

  getCustomerSoldMenus(): Array<MenuItem> {
    return [
      // {
      //   label: this.translate.get('Dashboard'),
      //   icon: 'pi pi-fw pi-desktop',
      //   routerLink: '/sold/dashboard'
      // },
      {label: 'Hóa đơn bán hàng', routerLink: '/sold/customer/invoice/hdbh', icon: 'pi pi-fw pi-book', visible: AuthService.hasPrivilege('SalesInvoice', 'SalesInvoice1', 'VIEW')},
      {label: 'Phiếu xuất kho', routerLink: '/sold/customer/invoice/pxk', icon: 'pi pi-fw pi-file', visible: AuthService.hasPrivilege('SalesInvoice', 'SalesInvoice1', 'VIEW')},
      {
        label: this.translate.get('HandleInvoice'),
        icon: 'pi pi-fw pi-check-square',
        styleClass: 'iam-menu-22',
        items: [
          {label: this.translate.get('CancelInvoice'), routerLink: '/sold/customer/invoice/cancelled', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceCancel', 'SalesInvoiceCancel1', 'VIEW')},
          {label: this.translate.get('ReplaceInvoice'), routerLink: '/sold/customer/invoice/replaced', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceReplace', 'SalesInvoiceReplace1', 'VIEW')},
          {label: this.translate.get('AdjustInvoice'), routerLink: '/sold/customer/invoice/adjusted', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SalesInvoiceAdjustment', 'SalesInvoiceAdjustment1', 'VIEW')},
          {
            label: 'Thông báo HĐ sai sót 04/SS-HĐĐT', routerLink: '/sold/customer/invoice/errors-announcement', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('ErrorAnnouncement', 'ErrorAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir78'
          }
        ]
      },
      {
        label: 'Báo cáo',
        icon: 'pi pi-fw pi-chart-pie',
        styleClass: 'iam-menu-22',
        items: [
          {label: 'Bảng kê hóa đơn đã sử dụng', routerLink: '/sold/customer/report/invoice-summary', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceSummaryReport', 'InvoiceSummaryReport1', 'VIEW')},
          {label: 'Bảng kê chi tiết hóa đơn đã sử dụng', routerLink: '/sold/customer/report/invoice-item-detail', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceItemDetailReport', 'InvoiceItemDetailReport1', 'VIEW')},
          {label: 'Bảng kê hóa đơn tổng hợp', routerLink: '/sold/customer/report/invoice-stat-by-tax', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceItemDetailReport', 'InvoiceItemDetailReport1', 'VIEW')},
          {label: this.translate.get('SummaryOfQuantityOfIssuedInvoices'), routerLink: '/sold/customer/report/invoice-template-stat', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('IssuedInvoiceCountReport', 'IssuedInvoiceCountReport1', 'VIEW')},
          {
            label: 'Báo cáo tình hình sử dụng hóa đơn', routerLink: '/sold/customer/report/invoice-using-report', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('InvoiceUsingReportReport', 'InvoiceUsingReportReport1', 'VIEW') && this.decreeCircular === 'DecCir32'
          },
          {
            label: 'Bảng tổng hợp dữ liệu HĐĐT', routerLink: '/sold/customer/report/invoice-stats-period', icon: 'pi pi-fw pi-circle-off',
            visible: AuthService.hasPrivilege('InvoiceStatsPeriod', 'InvoiceStatsPeriod1', 'VIEW') && this.decreeCircular === 'DecCir78'
          },
          {label: 'Báo cáo tờ khai giảm thuế', routerLink: '/sold/customer/report/invoice-tax-reduction', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Doanh thu theo sản phẩm', routerLink: '/sold/customer/report/sales-by-product', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Doanh thu theo bên mua', routerLink: '/sold/customer/report/sales-by-buyer', icon: 'pi pi-fw pi-circle-off'}
        ]
      },
      {
        label: this.translate.get('System'),
        icon: 'pi pi-fw pi-sliders-h',
        styleClass: 'iam-menu-16',
        items: [
          {
            label: this.translate.get('RegisterToIssue'),
            icon: 'pi pi-fw pi-external-link',
            styleClass: 'iam-menu-16',
            items: [
              {
                label: this.translate.get('Đăng ký/Thay đổi thông tin'), routerLink: '/sold/customer/register/using-register', icon: 'pi pi-fw pi-circle-off',
                visible: AuthService.hasPrivilege('UsingRegisterAnnouncement', 'UsingRegisterAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir78'
              },
              {label: this.translate.get('InvoiceTemplate'), routerLink: '/sold/customer/register/invoice-templates', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceTemplateMgr', 'InvoiceTemplateMgr1', 'VIEW')},
              {
                label: this.translate.get('ReleaseDecision'), routerLink: '/sold/customer/register/release-decision', icon: 'pi pi-fw pi-circle-off',
                visible: AuthService.hasPrivilege('ReleaseDecision', 'ReleaseDecision1', 'VIEW') && this.decreeCircular === 'DecCir32'
              },
              {
                label: this.translate.get('ReleaseAnnouncement'), routerLink: '/sold/customer/register/release-announcement', icon: 'pi pi-fw pi-circle-off',
                visible: AuthService.hasPrivilege('ReleaseAnnouncement', 'ReleaseAnnouncement1', 'VIEW') && this.decreeCircular === 'DecCir32'
              }
            ]
          },
          {
            label: this.translate.get('Dictionary'),
            icon: 'pi pi-fw pi-list',
            styleClass: 'iam-menu-16',
            items: [
              {label: this.translate.get('Buyers'), routerLink: '/sold/customer/category/buyers', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('PartyBuyer', 'PartyBuyer1', 'VIEW')},
              {label: this.translate.get('GoodsServices'), routerLink: '/sold/customer/category/products', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerProduct', 'SellerProduct1', 'VIEW')},
              {label: this.translate.get('Currency'), routerLink: '/sold/customer/category/currencies', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerCurrencies', 'SellerCurrencies1', 'VIEW')}
            ]
          },
          {
            label: this.translate.get('Lịch sử truyền nhận'),
            icon: 'pi pi-fw pi-sort-alt',
            styleClass: 'iam-menu-16',
            items: [
              {label: this.translate.get('ĐK/Thay đổi thông tin'), routerLink: '/sold/customer/tvan/agrt-using-register', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('AgrtUsingRegisterTvan', 'AgrtUsingRegisterTvan1', 'VIEW')},
              {label: this.translate.get('Hóa đơn điện tử'), routerLink: '/sold/customer/tvan/invoices', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('InvoiceTvan', 'InvoiceTvan1', 'VIEW')},
              {label: this.translate.get('Xử lý hóa đơn'), routerLink: '/sold/customer/tvan/agrt-error-announce', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('AgrtErrorAnnounceTvan', 'AgrtErrorAnnounceTvan1', 'VIEW')}
            ],
            visible: this.decreeCircular === 'DecCir78'
          },
          {label: this.translate.get('EmailHistoryLogs'), routerLink: '/sold/customer/system/email-messages', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerEmailLog', 'SellerEmailLog1', 'VIEW')},
          {label: 'Lịch sử tin nhắn Zalo', routerLink: '/sold/customer/system/zalo-messages', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SellerEmailLog', 'SellerEmailLog1', 'VIEW')},
          {label: 'Cấu hình hệ thống', routerLink: '/sold/customer/system/options', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasPrivilege('SystemConfig', 'SystemConfig1', 'VIEW')}
        ]
      }
    ];
  }

  getManagerSoldMenus(): Array<MenuItem> {
    return [
      // {
      //   label: this.translate.get('Dashboard'),
      //   icon: 'pi pi-fw pi-desktop',
      //   routerLink: '/sold/dashboard'
      // },
      {
        label: this.translate.get('Agent'),
        icon: 'pi pi-fw pi-sitemap',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('CreateAgent'), routerLink: '/sold/manager/q-agents/create', icon: 'pi pi-fw pi-plus-circle'},
          {label: this.translate.get('ListOfAgents'), routerLink: '/sold/manager/agents', icon: 'pi pi-fw pi-circle-off'},
        ]
      },
      {
        label: this.translate.get('Customer'),
        icon: 'pi pi-fw pi-users',
        styleClass: 'iam-menu-22',
        items: [
          {label: this.translate.get('Request'), routerLink: '/sold/manager/customer/requests', icon: 'pi pi-fw pi-circle-off'},
          {label: this.translate.get('ListOfCustomer'), routerLink: '/sold/manager/customer/customers', icon: 'pi pi-fw pi-circle-off'},
          {label: this.translate.get('ListOfAgreement'), routerLink: '/sold/manager/customer/agreements', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: this.translate.get('Payment'), routerLink: '/sold/manager/customer/payments', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Yêu cầu nâng cấp/mua thêm', routerLink: '/sold/manager/customer/customer-requests', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Đăng ký nhận tin nhắn zalo', routerLink: '/sold/manager/customer/zalo-requests', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])}
        ]
      },
      {
        label: this.translate.get('Dictionary'),
        icon: 'pi pi-fw pi-list',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('ServicePack'), routerLink: '/sold/manager/category/products', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Cơ quan thuế', routerLink: '/sold/manager/category/tax-authorities', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
        ],
        visible: AuthService.hasRoles(['Administrator'])
      },
      {
        label: this.translate.get('System'),
        icon: 'pi pi-fw pi-sliders-h',
        styleClass: 'iam-menu-16',
        items: [
          {label: this.translate.get('EmailHistoryLogs'), routerLink: '/sold/manager/system/email-messages', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Lịch sử tin nhắn Zalo', routerLink: '/sold/manager/system/zalo-messages', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          // {label: 'Thông tin nhà cung cấp', routerLink: '/sold/common/provider-info', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Quản lý tài khoản', routerLink: '/sold/manager/system/user-accounts', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Hóa đơn chưa nhận phản hồi của CQT', routerLink: '/sold/manager/system/invoice-gdt-processing', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])},
          {label: 'Thông báo hệ thống', routerLink: '/sold/manager/system/notifications', icon: 'pi pi-fw pi-circle-off', visible: AuthService.hasRoles(['Administrator'])}
        ]
      },
      {
        label: this.translate.get('Report'),
        icon: 'pi pi-fw pi-chart-line',
        styleClass: 'iam-menu-22',
        items: [
          {label: 'Báo cáo tổng hợp', routerLink: '/sold/manager/report/agreement-customer-summary', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Báo cáo chi tiết khách hàng', routerLink: '/sold/manager/report/agreement-customer-detail', icon: 'pi pi-fw pi-circle-off'},
          {label: 'Báo cáo đại lý', routerLink: '/sold/manager/report/agreement-agents', icon: 'pi pi-fw pi-circle-off'}
        ]
      }
    ];
  }

  setBreadcrumb(url?: string): void {
    if (this.menus.length) {
      let breadcrumb: string = '';
      for (const menu of this.menus) {
        if (this.isActive(menu.routerLink) && menu.label) {
          breadcrumb = menu.label;
          break;
        } else if (this.isActive(`${menu.routerLink}/create`) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Create')}`;
          break;
        } else if (this.isActive(`${menu.routerLink}/detail`, true) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Detail')}`;
          break;
        } else if (this.isActive(`${menu.routerLink}/update`, true) && menu.label) {
          breadcrumb = `${menu.label}(-)${this.translate.get('Edit')}`;
          break;
        }
        if (menu.items) {
          for (const item of menu.items) {
            if (this.isActive(item.routerLink) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}`;
              break;
            } else if (this.isActive(`${item.routerLink}/create`) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Create')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/detail`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Detail')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/update`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Edit')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/agreement`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Agreement')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/minutes`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Minutes')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/decision`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Decision')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/replace`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Replace')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/adjust`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Adjust')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/request`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Request')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/renew`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Renew')}`;
              break;
            } else if (this.isActive(`${item.routerLink}/new`, true) && item.label) {
              breadcrumb = `${menu.label}(-)${item.label}(-)${this.translate.get('Create')}`;
              break;
            }
          }
        }
        if (breadcrumb) {
          break;
        }
      }
      BreadcrumbService.setBreadcrumb(breadcrumb);
      if (url) {
        BreadcrumbService.setButtons([], url);
      }
    }
  }

  isActive(url: string, subset?: boolean): boolean {
    const mode = subset ? 'subset' : 'exact';
    return this.router.isActive(url, {paths: mode, queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'});
  }

}
