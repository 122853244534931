<p-table [value]="items" [lazy]="true" [paginator]="true" [rows]="10" [totalRecords]="totalRecords"
         [rowsPerPageOptions]="[10,20,30,50,100,200,500]" [showCurrentPageReport]="true"
         (onLazyLoad)="loadLazy($event)" dataKey="orderId" selectionMode="single" [(selection)]="selectedItem"
         styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines iam-datatable-scrollable-x">
  <ng-template pTemplate="caption">
    <div class="table-header">
      {{ totalRecords|number }} {{ 'Agreement'|translate }}
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col [ngStyle]="{'width': '40px', 'min-width': '40px'}">
      <col [ngStyle]="{'width': '130px', 'min-width': '130px'}">
      <col [ngStyle]="{'width': '180px', 'min-width': '180px'}">
      <col [ngStyle]="{'min-width': '170px'}">
      <col [ngStyle]="{'min-width': '170px'}">
      <col [ngStyle]="{'width': '140px', 'min-width': '140px'}">
      <col [ngStyle]="{'width': '140px', 'min-width': '140px'}">
      <col [ngStyle]="{'width': '140px', 'min-width': '140px'}">
      <col [ngStyle]="{'width': '160px', 'min-width': '160px'}">
      <col [ngStyle]="{'width': '80px', 'min-width': '80px'}">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="orderId">{{ 'PseudoId'|translate }}
        <p-sortIcon field="orderId"></p-sortIcon>
      </th>
      <th pSortableColumn="agreementDate">{{ 'AgreementDate'|translate }}
        <p-sortIcon field="agreementDate"></p-sortIcon>
      </th>
      <th pSortableColumn="agreementTypeEnum">{{ 'AgreementType'|translate }}
        <p-sortIcon field="agreementTypeEnum"></p-sortIcon>
      </th>
      <th pSortableColumn="agreementNo">{{ 'AgreementNo'|translate }}
        <p-sortIcon field="agreementNo"></p-sortIcon>
      </th>
      <th pSortableColumn="partTotal">{{ 'InvoiceTotal'|translate }}
        <p-sortIcon field="partTotal"></p-sortIcon>
      </th>
      <th pSortableColumn="agreementStatus">{{ 'Status'|translate }}
        <p-sortIcon field="agreementStatus"></p-sortIcon>
      </th>
      <th pSortableColumn="isPaid">{{ 'Paid'|translate }}
        <p-sortIcon field="isPaid"></p-sortIcon>
      </th>
      <th pSortableColumn="paidDate">{{ 'Ngày thanh toán'|translate }}
        <p-sortIcon field="paidDate"></p-sortIcon>
      </th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter type="text" field="orderId" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
      </th>
      <th></th>
      <th>
        <p-columnFilter type="text" field="agreementTypeEnum" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="agreementNo" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="partTotal" matchMode="numeric" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter type="text" field="agreementStatus" matchMode="contains" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="isPaid" matchMode="equals" [showMenu]="false" [showClearButton]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown [ngModel]="value" [options]="booleans" (onChange)="filter($event.value)" placeholder="-" [showClear]="true" appendTo="body"></p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-expanded="expanded">
    <tr [pSelectableRow]="rowData">
      <td>
        <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{ rowData['orderId'] }}</td>
      <td>{{ rowData['agreementDate']|date:'dd/MM/yyyy HH:mm:ss' }}</td>
      <td>{{ rowData['agreementTypeEnum'] }}</td>
      <td>{{ rowData['agreementNo'] }}</td>
      <td class="p-text-right">{{ rowData['partTotal']|number }}</td>
      <td>{{ rowData['agreementStatus'] }}</td>
      <td class="p-text-center">
        <i class="pi" [ngClass]="{'true-icon pi-check-circle': rowData['isPaid'] === 'Y', 'false-icon pi-times-circle': rowData['isPaid'] !== 'Y'}"></i>
      </td>
      <td>{{ rowData['paidDate']|date:'dd/MM/yyyy' }}</td>
      <td class="p-text-center action-column">
        <p-button icon="pi pi-file-pdf" styleClass="p-button-rounded p-button-success p-button-sm p-mr-1" title="{{ 'Download+Pdf'|translate }}" (onClick)="viewPdf(rowData)"></p-button>
        <p-button icon="pi pi-user-edit" styleClass="p-button-rounded p-button-info p-button-sm" title="Ký điện tử" (onClick)="sign(rowData)"></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-order>
    <tr class="row-expansion">
      <td colspan="9">
        <app-order-products [order]="order"></app-order-products>
      </td>
    </tr>
  </ng-template>
</p-table>
